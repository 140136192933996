/* global axios, store, io, defaultTheme */
/* eslint-disable no-console, no-unused-vars */

function replaceSpecialChars(str)
{
    str = str.replace(/[ÀÁÂÃÄÅ]/,"A");
    str = str.replace(/[àáâãäå]/,"a");
    str = str.replace(/[ÈÉÊË]/,"E");
    str = str.replace(/[èéêë]/,"e");
    str = str.replace(/[ÌÍÎÏ]/,"I");
    str = str.replace(/[ìíîï]/,"i");
    str = str.replace(/[ÒÓÔÕÖ]/,"O");
    str = str.replace(/[òóôõö]/,"o");
    str = str.replace(/[ÙÚÛÜ]/,"U");
    str = str.replace(/[ùúûü]/,"u");
    return str;
}

function score(target, query, fuzzyFactor,separator = " ") {
    //console.log("Custom Ofidirect");
    // If the string is equal to the word, perfect match.
    if (query === '')
        return 0;
    if (target === query)
        return 1;

    let runningScore = 0;
    let targetLower = replaceSpecialChars(target.toLowerCase().trim());
    let targetLen = target.length;
    let queryLower = replaceSpecialChars(query.toLowerCase().trim());
    let queryLen = query.length;
    let startAt = 0;
    let fuzzies = 1;
    let queryWords = queryLower.split(" ");
    let haveSubWords = 0;
    for(let queryWord of queryWords)
        if(targetLower.indexOf(queryWord) !== -1)
            haveSubWords += 1;
    if(haveSubWords !== queryWords.length)
        return 0;
    // Calculate fuzzy factor
    fuzzyFactor = fuzzyFactor ? 1 - fuzzyFactor : 0;

    // Walk through query and add up scores.
    // Code duplication occurs to prevent checking fuzziness inside for loop
    for (let i = 0; i < queryLen; i += 1) {
        // Find next first case-insensitive match of a character.
        let idxOf = targetLower.indexOf(queryLower[i], startAt);

        if (idxOf === -1) {
            if (fuzzyFactor) {
                fuzzies += fuzzyFactor;
            } else {
                return 0;
            }
        } else {
            let charScore = 0;
            if (startAt === idxOf) {
                // Consecutive letter & start-of-string Bonus
                charScore = 0.7;
            } else {
                charScore = 0.1;

                // Acronym Bonus
                // Weighing Logic: Typing the first character of an acronym is as if you
                // preceded it with two perfect character matches.
                if (target[idxOf - 1] === ' ') {
                    charScore += 0.8;
                }
            }

            // Same case bonus.
            if (target[idxOf] === query[i]) {
                charScore += 0.1;
            }

            // Update scores and startAt position for next round of indexOf
            runningScore += charScore;
            startAt = idxOf + 1;
        }
    }

    // Reduce penalty for longer strings.
    let finalScore = 0.5 * (runningScore / targetLen + runningScore / queryLen) / fuzzies;

    if ((queryLower[0] === targetLower[0]) && (finalScore < 0.85)) {
        finalScore += 0.15;
    }

    if ((queryLower[0] === targetLower[0]) && (finalScore < 0.85)) {
        finalScore += 0.15;
    }

    for(let queryWord of queryWords) {
        if (targetLower.startsWith(queryLower + separator) || targetLower.endsWith(separator + queryLower)) {
            finalScore += 0.6;
        } else if (targetLower.startsWith(queryWord))
            finalScore += 0.3;
        else if (targetLower.indexOf(separator + queryWord + separator) !== -1)
            finalScore += 0.6;
        else if (targetLower.indexOf(queryWord + separator) !== -1 || targetLower.indexOf(separator + queryWord) !== -1)
            finalScore += 0.3;
        else if (targetLower.indexOf(queryWord) !== -1)
            finalScore += 0.25;
    }

    return finalScore;
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function initWebSocket(){
    let socket = io.connect();
    socket.on("newSettings", function (event) {
        console.log("newSettings event",event);
        if (event.fields)
            store.commit('updateSetting', event.fields);
    });
    socket.on("stockChange", function (event) {
        console.log("Client Stock",event);
        if(event.record && event.record.fields) {
            let storeItem = store.getters.getItemByCode(event.record.fields.ArtCode);
            console.log(storeItem.Qty);
            if(storeItem) {
                storeItem.Qty = event.record.fields.Qty;
                if(event.record.fields.Reserved)
                    storeItem.Qty -= event.record.fields.Reserved;
            }
            console.log(storeItem.Qty);
            store.state.items_store.set(event.record.fields.ArtCode,storeItem);
            window.$Event.$emit("stockChange", storeItem.Code);
        }

    });

    socket.on("serverOnline", function (msg) {
        store.commit('updateSocketReady',msg.success);
    });
    socket.on("itemInsert",function (msg) {
        console.log('Hello',JSON.parse(msg));
        store.dispatch('eventIOItem',{action:'updateStore',value:JSON.parse(msg)});
    });
    socket.on("itemUpdate", function (msg) {
        console.log('Hello',JSON.parse(msg));
        store.dispatch('eventIOItem',{action:'updateStore',value:JSON.parse(msg)});
    });
    socket.on("itemDelete", function (msg) {
        console.log('Hello Delete');
        store.dispatch('eventIOItem',{action:'deleteFromStore',value:msg});
    });
}

function  ArrayToMap(arrayObject, Key) {
    let keyMap = new Map();
    for (let item of arrayObject)
        keyMap.set(item[Key], item);
    return keyMap;
}

// eslint-disable-next-line no-unused-vars
function GetSubCategoryPath(catCode, tail, Path, isSubCat = false) {
    if (catCode === tail.code) {
        let temp = Object.assign({}, tail);
        delete temp.children;
        Path.push(temp);
        isSubCat = true;
    }
    if(isSubCat){
        if (tail.children) {
            for (let children of tail.children) {
                GetSubCategoryPath(catCode, children, Path, true);
                let temp = Object.assign({}, children);
                delete temp.children;
                Path.push(temp);
            }
        }
    }
    else {
        if (tail.children) {
            for (let children of tail.children) {
                GetSubCategoryPath(catCode, children, Path, false);
            }
        }
    }
}

function GetCategoryPath(catCode, tail, Path) {
    if(!tail)
        return false;
    if (catCode === tail.code) {
        let temp = Object.assign({}, tail);
        delete temp.children;
        Path.push(temp);
        return true;
    }
    else {
        if (tail.children) {
            for (let children of tail.children) {
                let isPath = GetCategoryPath(catCode, children, Path);
                if (isPath) {
                    let temp = Object.assign({}, children);
                    delete temp.children;
                    if(temp.code !== catCode)
                        Path.push(temp);
                    return true;
                }
            }
        }
    }
    return false;
}

function ProcessItems(responseItems,state) {
    if(!responseItems  || responseItems.length === 0)
        return [];
    let fields = Object.keys(responseItems[0]);
    return window.__lodash__.map(responseItems, (x) => {
        let store_item = state.items_store.get(x.Code);
        x.carQty = 1;
        x.fieldToSearch = "";
        x.SearchScore = 0;
        x.BasePrice = x.Price;
        x.HiddenFilters = 0;
        x.SpecialPrice = store_item ? store_item.SpecialPrice : false;
        x.RowNet = null ;
        x.RowTotal =  null;
        x.VATPrice = null;
        x.isCalculate = false;
        x.visible = false;
        if(x.Qty < 0)
            x.Qty = 0;
        if(store_item){
            x.isCalculate = store_item.isCalculate;
            x.Price = store_item.Price;
            if(store_item.RowNet)
                x.RowNet =  store_item.RowNet;
            if(store_item.RowTotal)
                x.RowTotal = store_item.RowTotal;
            if(store_item.VATPrice)
                x.VATPrice = store_item.VATPrice;
        }
        for (let field of fields) {
            if(x[field])
                x.fieldToSearch += x[field].toString().toLowerCase() + " *|* ";
        }
        return x;
    });
}

async function nativeSaveCarSession(state, from , items) {
    return new Promise ((resolve, reject) => {
        wrapPost(`/${defaultTheme.fields.PathURL}/api/saveCarSession`, {state, from, items})
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error); 
            });
    });
}

async function nativeGetTempSalerOrder(tempItems, payload) {
    return await wrapPost(`/${defaultTheme.fields.PathURL}/api/getTempSalesOrder`, {
        items: tempItems,
        extras: payload
    });
};

const ecommerceAxios = axios.create();

ecommerceAxios.interceptors.request.use(function (config) {
    if(defaultTheme.fields.PathURL && defaultTheme.fields.PathURL !== 'ecommerce')
        config.url =  config.url.replace('/ecommerce/',`/${defaultTheme.fields.PathURL}/`);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// eslint-disable-next-line no-undef
ecommerceAxios.defaults.withCredentials = true;

//eslint-disable-next-line no-unused-vars
const wrapPost = async (url, body, options)=>{
    try {
        if (body && body.body)
            body = body.body;
        return ecommerceAxios.post(url, body, options);
    } catch(err) {
        console.error(err);
        return null;
    }
};

//eslint-disable-next-line no-unused-vars
const wrapGet = async (url, params = {}, options = null) => {
    try {
        if (params && params.params)
            params = params.params;
        return ecommerceAxios.get(url, {params});
    } catch(err) {
        console.error(err);
        return null;
    }
};